import { Injectable } from '@angular/core';
import { AjaxService } from './ajax.service';
import { BrowserKeys, CustomerAPIPath, Environment, FloorAPIPath, ListAPIPath, CommonListAPIPath, ProviderAPIPath, SiteAPIPath, ProjectAPIPath } from '../constants';
import { CommonService } from './common.service';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ListService {

  constructor(private ajaxService: AjaxService,
    private commonService: CommonService,
    private http: HttpClient,
    private readonly env: Environment,
    private authService: AuthService) { }

  getAdminSpecificRoleList(): any {
    return this.ajaxService.get(`${ListAPIPath.admintRole}`);
  }
  getActiveCustomers(): any {
    return this.ajaxService.get(`${CustomerAPIPath.activeCustomers}`);
  }
  getActiveCustomersByUser(isTicketFromHeadquarterDashboard = false): any {
    if(isTicketFromHeadquarterDashboard){
      return this.ajaxService.get(`${CustomerAPIPath.activeCustomersByUser}?isTicketing=true`);  
    }
    return this.ajaxService.get(`${CustomerAPIPath.activeCustomersByUser}`);
  }

  getEquipmentList(): any {
    return this.ajaxService.get(FloorAPIPath.category);
  }
  getGlobalRoleList(): any {
    return this.ajaxService.get(ListAPIPath.globalRole);
  }
  getStatusData() {
    return this.ajaxService.get(ListAPIPath.status)
  }
  getConnectionPDF(id: any, buildingId: any, floorId: any, isFromProject: any) {
    const data = 'Bearer ' + this.commonService.getLocalStorageString(BrowserKeys.token);
    const httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': data,
        'customerId': this.authService.getCustomerId().customerId.toString()
      }),
      reportProgress: true,
      // responseType: 'text'
    };
    httpOptions['responseType'] = 'text'
    let params: any;
    if (isFromProject) {
      params = "projectId=" + id + "&buildingId=" + buildingId + "&floorId=" + floorId
    } else {
      params = "siteId=" + id + "&buildingId=" + buildingId + "&floorId=" + floorId
    }
    const req = new HttpRequest('GET', `${this.env.apiPath}${FloorAPIPath.switchFloorsPDF}?${params}`, httpOptions);
    return this.http.request(req);
  }

  getCustomerSpecificRoleList(): any {
    return this.ajaxService.get(CommonListAPIPath.customerSpecificRole);
  }

  getCustomerSpecificProjectRoleList(): any {
    return this.ajaxService.get(CommonListAPIPath.customerSpecificProjectRole);
  }

  getProviderCustomerById(id: any) {
    return this.ajaxService.get(`${ProviderAPIPath.provider}/${id}/associatedcustomer`);
  }
  multiPlanPrintOut(id: any, params: any, isFromSite: any) {
    const data = 'Bearer ' + this.commonService.getLocalStorageString(BrowserKeys.token);
    const httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': data,
        'customerId': this.authService.getCustomerId().customerId.toString()
      }),
      reportProgress: true,
      // responseType: 'text'
    };

    httpOptions['responseType'] = 'text'

    let url = this.env.apiPath;
    if (this.env.envName === 'Staging' || this.env.envName === 'live') {
      url = this.env.reportApiPath;
    }
    if (isFromSite) {
      url += SiteAPIPath.site + '/' + id + '/multiPlanPrint';
    } else {
      url += ProjectAPIPath.project + '/' + id + '/multiPlanPrint';
    }

    const req = new HttpRequest('POST', `${url}`, params, httpOptions);

    return this.http.request(req);
  }
  getPrintOutConfigurationColumn(isProject: boolean): any {
    if (isProject) {
      return this.ajaxService.get(FloorAPIPath.projectReportConfigColumn);
    } else {
      return this.ajaxService.get(FloorAPIPath.siteReportConfigColumn);
    }
  }

  getConfigDeviceFields(): any {
    return this.ajaxService.get(`${FloorAPIPath.configReportFavoriteFields}/field`);
  }
  getSitesIdAndName(isImage?: boolean): any {
    if (isImage) {
      return this.ajaxService.get(SiteAPIPath.siteDropdownList + '?isThumbImage=true');
    } else {
      return this.ajaxService.get(SiteAPIPath.siteDropdownList);
    }
  }
}
