/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import moment from 'moment/moment';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';

@Component({
  selector: 'so-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnDestroy, OnInit {

  private readonly destroy$ = new Subject<void>();

  @Input() inputControlName!: FormControl;
  @Input() className!: any;
  @Input() bsConfig: any = {};
  @Input() id: any;
  @Input() disabled = false;
  @Output() datePickerChange = new EventEmitter();
  @Output() blur = new EventEmitter();
  @ViewChild('dp') dp: any;

  customButtons$: any;
  error = true

  dpCustomButtons = `
<button id="dpTodayButton" class="two btn btn-success dpCustomButton">Today</button>
`;
  isOpen = false;
  @HostListener('document:mousedown', ['$event'])
  mouseEvent(event: any) {
    if (document.getElementsByTagName('bs-datepicker-container').length > 0 && !document.getElementsByTagName('bs-datepicker-container')[0].contains(event.target)) {
      if (this.dp) this.dp.hide();
    }
  }
  handler(): void {
    // const datepickerContainers = document.querySelectorAll('.bs-datepicker-container');
    // if (datepickerContainers.length > 1) {
    //   // Convert NodeList to Array and sort by their position in the DOM
    //   const containersArray = Array.from(datepickerContainers);
    //   containersArray.sort((a, b) => a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_FOLLOWING ? -1 : 1);

    //   // Remove all except the last one
    //   containersArray.slice(0, -1).forEach(container => container.remove());
    // }

    const d1: any = document.querySelector('bs-datepicker-navigation-view')

    d1.insertAdjacentHTML('beforeend', this.dpCustomButtons);

    const dpTodayButton = document.getElementsByClassName('dpCustomButton');
    this.customButtons$ = fromEvent(dpTodayButton, 'click')

    this.customButtons$.pipe(takeUntil(this.destroy$)).subscribe((e: any) => {
      this.circulateEvent(e);
      this.hideDatePicker();
    })

  }

  circulateEvent(e: any) {
    switch (e.target.id) {
      case 'dpTodayButton':
        this.setDate();
        // this.hideDatePicker();
        break;

      case 'dpResetButton':
        this.inputControlName.setValue(null);
        this.hideDatePicker();
        break;

      case 'dpCloseButton':
        this.hideDatePicker();
        break;

    }
  }

  setDate() {
    this.inputControlName.setValue(new Date());
  }

  hideDatePicker() {
    this.isOpen = false;
  }
  onValueChange(event: any) {
    if (event === undefined) {
      this.inputControlName.setValue("");
      this.datePickerChange.emit({ data: "" });
    } else {
      this.datePickerChange.emit({ data: moment(event).format('MM/DD/YYYY') });
    }
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngOnInit(): void {
    this.bsConfig['showWeekNumbers'] = false;
    this.bsConfig['containerClass'] = 'theme-default';
    this.bsConfig['adaptivePosition'] = 'true';
    this.bsConfig['customTodayClass'] = 'today';

  }
  onBlur(event: any) {
    this.blur.emit(event);
  }

  clear() {
    this.inputControlName.setValue("");
  }
}
