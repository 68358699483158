import { Injectable } from "@angular/core";
import { AjaxService } from "./ajax.service";
import { CustomerAPIPath, CustomerUserAPIPath, FloorAPIPath, ListAPIPath, ProviderAPIPath, PublicAPIPath, UserAPIPath } from "../constants/api.constant";
import { LocalStorageKeys } from "../constants/common.constant";
import { CommonService } from "./common.service";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private ajaxService: AjaxService, private commonService: CommonService, private authService: AuthService) {

    }
    // globalSearch(keys: any): any {
    //     return this.ajaxService.post(`${UserAPIPath.GlobalSearch}`, keys);
    // }

    changePassword(reqData: any): any {
        return this.ajaxService.put(UserAPIPath.changePassword, reqData);
    }

    verifyResetPasswordToken(email: string, token: string) {
        return this.ajaxService.get(`${PublicAPIPath.resetPassword}?email=` + encodeURIComponent(`${email}`) + `&token=${token}`, {});
    }
    getUser(): any {
        return this.ajaxService.get(`${UserAPIPath.user}/profile`);
    }


    // getDeviceSize(floorId: number): any {
    //     if (floorId > 0 && floorId != undefined) {
    //         return this.ajaxService.get(`${UserAPIPath.ShowDeviceSize}/${floorId}/showdevicesize/equipment`);
    //     }
    //     return false;
    // }

    // setDeviceSize(floorId: number, scale: any): any {
    //     if (floorId > 0 && floorId != undefined) {
    //         return this.ajaxService.post(`${UserAPIPath.ShowDeviceSize}/${floorId}/showdevicesize/equipment?showdevicesize=${scale}`, {});
    //     }
    //     return false;
    // }
    // gethideLiveDevicesChoice(floorId: number): any {
    //     if (floorId > 0 && floorId != undefined) {
    //         return this.ajaxService.get(`${UserAPIPath.hideLiveDevices}/${floorId}/hidelivedevice/equipment`);
    //     }
    //     return false;
    // }
    // checkUserSiteOrProjectBuldingFloorActiveOrNot(data: any) {
    //     return this.ajaxService.post(`${UserAPIPath.checkLocalSaveIndexDB}`, data);
    // }
    // getPinColor(floorId: number): any {
    //     if (floorId > 0 && floorId != undefined) {
    //         return this.ajaxService.get(`${UserAPIPath.showPinColor}/${floorId}/showpincolor/equipment`);
    //     }
    //     return false;
    // }
    savePinColor(floorId: any, color: any) {
        if (floorId > 0 && floorId != undefined) {
            return this.ajaxService.post(`${FloorAPIPath.floor}/${floorId}/showpincolor/equipment?showpincolor=${color}`, null);
        }
        return;
    }
    getUserDetails() {
        return this.ajaxService.get(`${CustomerAPIPath.customerUserDetails}?requestFrom=web`);
    }
    getMostRecentCustomers(): any {
        return this.ajaxService.get(`${UserAPIPath.recentCustomer}`);
    }
    getActiveCustomers(): any {
        return this.ajaxService.get(`${CustomerAPIPath.activeCustomers}`);
    }
    getActiveCustomersByUser(): any {
        return this.ajaxService.get(`${UserAPIPath.activeCustomersByUser}`);
    }
    globalSearch(keys: any): any {
        return this.ajaxService.post(`${CustomerAPIPath.GlobalSearch}`, keys);
    }
    getPageSize() {
        return this.authService.getUserData().pageSize ? parseFloat(this.authService.getUserData().pageSize) : 20;
    }

    setPageSize(pageSize: any) {
        const user = this.authService.getUserKeyData();
        user.user.pageSize = pageSize;
        this.commonService.setLocalStorageObject('userData', user)
        return this.ajaxService.put(`${UserAPIPath.userDetails}?pageSize=${pageSize}`, {});
    }

    getFilteredUsers(filterObj: any): any {
        return this.ajaxService.post(`${UserAPIPath.users}`, filterObj);
    }
    globalListorGridTab(globalListOrGridTab: string) {
        return this.ajaxService.put(`${UserAPIPath.userDetails}?isListOrGridTab=${globalListOrGridTab}`, {});
    }
    getProviderUserById(id: any): any {
        return this.ajaxService.get(`${ProviderAPIPath.provider}/${id}/users`);
    }
    requestForProviderCustomer(user: any) {
        return this.ajaxService.put(`${ProviderAPIPath.provider}/acceptinvite`, user);
    }
    removeProviderCustomer(id: any) {
        return this.ajaxService.delete(`${ProviderAPIPath.provider}/customer/${id}`);
    }
    setConfiguration(providerId: any, param: any, value: any): any {
        return this.ajaxService.get(`${ProviderAPIPath.provider}/${providerId}/configuration?${param}=${value}`);
    }
    getUserById(userId: any) {
        return this.ajaxService.get(`${UserAPIPath.user}/profile/${userId}`);
    }
    userProjects(): any {
        return this.ajaxService.get(`${ListAPIPath.userProjects}`);
    }
    customerUserProjects(userId: any): any {
        return this.ajaxService.get(`${UserAPIPath.user}/${userId}/projects`);
    }
    userProviderSites(userId: any): any {
        return this.ajaxService.get(`${CustomerUserAPIPath.userProviderSites}/${userId}/sites`);
    }
    requestForProvider(user: any) {
        return this.ajaxService.post(`${ProviderAPIPath.provider}/user/request`, user);
    }
    updateUser(user: any) {
        return this.ajaxService.put(`${UserAPIPath.user}/profile`, user);
    }
    createAccount(user: any) {
        return this.ajaxService.put(`${UserAPIPath.user}/createAccount`, user);
    }
    getLinkedCustomerToProviderAndUser(providerId: any) {
        return this.ajaxService.get(`${ProviderAPIPath.provider}/${providerId}/activeandlinked/customer`);
    }
    getProviderCustomerById(id: any): any {
        return this.ajaxService.get(`${ProviderAPIPath.provider}/${id}/associatedcustomer`);
    }
    updateUserMFA(email: any) {
        return this.ajaxService.put(`${PublicAPIPath.updateMFA}?email=${email}`, {});
    }
    logout() {
        return this.ajaxService.post(`${UserAPIPath.logout}`,{});
    }
}
