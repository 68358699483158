<div class="loader" *ngIf="!downloadloader"
    [ngClass]="{'popup-loader':popupLoader,'full-screen-loader': fullpageloader, 'pixie-popup-loader': pixieLoader, 'page-loader': pageloader,'table-loader':tableloader, 'small-loader':smallloader, 'xs-loader':xsloader, 'main-page-loader':mainpageloader,  'download-loader':downloadloader}">
    <i>
        <svg width="65px" class="spinner" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30">
            </circle>
        </svg>
        <svg class="loader-logo" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 17 30"
            style="enable-background:new 0 0 17 30;" xml:space="preserve">

            <g>
                <path class="st0"
                    d="M16.7,29.5v-7.7C16,23,15.1,24,14,24.8c-0.3,0.2-0.6,0.4-0.9,0.6C14.8,26.3,16.1,27.7,16.7,29.5L16.7,29.5z" />
                <path class="st0" d="M4.6,8.9c-0.3-1.1-0.8-2.2-1.7-3C3.4,7,3.6,8.3,3.5,9.5c-1.2,0.8-2.2,1.8-3,3.1c0.6-4,0.7-8.1,0-12.1l7.8,7.4
    		C7,8.1,5.8,8.4,4.6,8.9z" />
                <path class="st0" d="M16.8,16.1l-0.9-0.9L9.5,9c-1.7-0.2-3.6,0.1-5.1,1c-4,2.2-5.5,7.4-3.3,11.4s7.4,5.5,11.4,3.3
    		c0.1,0,0.2-0.1,0.3-0.1C15.7,22.8,17.3,19.5,16.8,16.1z M8.5,20.3c-1.6,0-2.9-1.3-2.9-2.9s1.3-2.9,2.9-2.9s2.9,1.3,2.9,2.9l0,0
    		C11.5,19,10.2,20.3,8.5,20.3C8.6,20.3,8.5,20.3,8.5,20.3z" />
            </g>
        </svg>
    </i>
</div>

<i class="download-loader" *ngIf="downloadloader">
    <svg width="65px" class="spinner" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30">
        </circle>
    </svg>
    <svg class="loader-logo" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 17 30"
        style="enable-background:new 0 0 17 30;" xml:space="preserve">

        <g>
            <path class="st0"
                d="M16.7,29.5v-7.7C16,23,15.1,24,14,24.8c-0.3,0.2-0.6,0.4-0.9,0.6C14.8,26.3,16.1,27.7,16.7,29.5L16.7,29.5z" />
            <path class="st0" d="M4.6,8.9c-0.3-1.1-0.8-2.2-1.7-3C3.4,7,3.6,8.3,3.5,9.5c-1.2,0.8-2.2,1.8-3,3.1c0.6-4,0.7-8.1,0-12.1l7.8,7.4
        		C7,8.1,5.8,8.4,4.6,8.9z" />
            <path class="st0" d="M16.8,16.1l-0.9-0.9L9.5,9c-1.7-0.2-3.6,0.1-5.1,1c-4,2.2-5.5,7.4-3.3,11.4s7.4,5.5,11.4,3.3
        		c0.1,0,0.2-0.1,0.3-0.1C15.7,22.8,17.3,19.5,16.8,16.1z M8.5,20.3c-1.6,0-2.9-1.3-2.9-2.9s1.3-2.9,2.9-2.9s2.9,1.3,2.9,2.9l0,0
        		C11.5,19,10.2,20.3,8.5,20.3C8.6,20.3,8.5,20.3,8.5,20.3z" />
        </g>
    </svg>
</i>