/* eslint-disable no-prototype-builtins */
import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Roles, ProviderRole, AuthService, CommonService, Utilities, LocalStorageKeys, EventEmitterType, EventService } from '@SiteOwl/core';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';


@Component({
    selector: 'so-left-bar',
    templateUrl: './left-bar.component.html',
    styleUrls: ['./left-bar.component.scss'],
})
export class LeftBarComponent {
    isUser!: boolean;
    isCustomerViewOnly!: boolean;
    isCustomerManager!: boolean;
    isCustomerAdministator!: boolean;
    isCustomerTechnician!: boolean;
    isCustomerAgent!: boolean;
    isViewOnly!: boolean;
    isAdministrator!: boolean;
    // isDesigner: boolean;


    user: any;
    roles: any = Roles;
    isCustomer!: boolean;
    isTechnician!: boolean;
    canAccessCustomerUsers!: boolean;
    hideLeftMenu!: boolean
    private subscription!: Subscription;
    private headerSub!: Subscription;
    userToken: any;
    expandsideBarClass = false;
    subClosescription!: Subscription;
    providerId: any;
    helpSubmenu = false;
    helpPopupClicked!: boolean;
    isProviderAdminOrCoordinator: any;
    providerRole = ProviderRole;
    showPlanLibrary = false;
    isUserProvider = false;
    isProjectManager!: boolean;
    isLiveSystemAccessForCustomer: any;
    isTicketAccessible=false;
    isLiveSystemAccessForProvider: any;
    userDetails: any;
    isTicketSubmitter!: boolean;
    isCustomerTicketSubmitter!: boolean;
    userUpdateDetailSub!: Subscription;
    updateMenuWhileEnableDisable!: Subscription;
    constructor(private authService: AuthService,
        private eventService: EventService,
        private commonService: CommonService,
        private router: Router) {
        this.subscribe();
    }
    @HostListener('window:click', ['$event'])
    handleClick(event: any) {
        if (!this.helpPopupClicked) {
            this.helpSubmenu = false
        }
        this.helpPopupClicked = false
    }
    isLinkActiveForPlanLibrary(): boolean {

        return (this.router.url.indexOf('/secure/live-site/library') >= 0)
    }
    isLinkProjectDeviceActive(): boolean {
        if (this.getVisitedMenu() === 'project') {
            return true;
        }
        return (this.router.url.indexOf('secure/project/devices') >= 0)
    }
    isLinkActive(): boolean {
        if (this.router.url.indexOf('/secure/live-site/list') >= 0 || this.router.url.indexOf('/secure/site/design') >= 0) {
            return true;
        } else if (this.getVisitedMenu() === 'site') {
            return true;
        }
        return false;
    }
    isAuditActive(): boolean {

        if (this.getVisitedMenu() === 'audit') {
            return true;
        }
        return false;
    }

    ngOnInit(): void {
        this.user = this.authService.getUserData();
        this.userDetails = this.authService.getUserKeyData();
        this.userToken = this.authService.getDecodedJWTToken();
        if (this.user.providerId) {
            this.isUserProvider = true;
            this.providerId = this.user.providerId;
            this.isProviderAdminOrCoordinator = (this.user.providerRole.name !== this.providerRole.StaffMember && this.user.providerRole.name !== this.providerRole.Technician);
        }

        if ((this.user.role.name === Roles.Administrator || this.user.role.name === Roles.Manager)) {
            this.showPlanLibrary = true;
        }
        this.checkCustomer();
        this.headerSub = this.eventService.subscribe(EventEmitterType.RouteChanged, (payload: any) => {
            this.hideLeftMenu = payload.hideLeftMenu;
            this.user = this.authService.getUserKeyData();
            this.checkGlobalRole();
            this.checkCustomerRole();
            this.checkCustomer();
        })
        this.userUpdateDetailSub = this.eventService.subscribe(EventEmitterType.userUpdatedDetails, (userData: any) => {
            console.log("data", userData);
            if (userData && (userData.user.providerId === null || userData.user.providerId === undefined)) {
                this.isUserProvider = false;
                this.providerId = null;
                this.isProviderAdminOrCoordinator = false;
            } else {
                if (userData && userData.user.providerId) {
                    this.isUserProvider = true;
                    this.providerId = userData.user.providerId;
                    this.isProviderAdminOrCoordinator = (userData.user.providerRole.name !== this.providerRole.StaffMember && userData.user.providerRole.name !== this.providerRole.Technician);
                }
            }
        })

    }
    private checkGlobalRole() {
        this.isUser = this.authService.getUserKeyData().userRole === Roles.User;
        this.isAdministrator = this.authService.getUserKeyData().userRole === Roles.Administrator;
        this.isLiveSystemAccessForCustomer = this.userDetails.isLiveSystemAccessForCustomer;
        this.isTicketAccessible = this.userDetails.isTicketing;
        this.isLiveSystemAccessForProvider = !Utilities.isNull(this.user.user.providerName) ? this.user.user.isLiveSystemAccessForProvider : null;
    }
    private checkCustomerRole() {
        this.isCustomerTechnician = this.userDetails.customerRoleName === Roles.Technician;
        this.isCustomerTicketSubmitter = this.userDetails.customerRoleName === Roles.TicketSubmitter;
        this.isCustomerManager = this.userDetails.customerRoleName === Roles.Manager;
        this.isCustomerAdministator = this.userDetails.isAdmin;
        this.isCustomerViewOnly = this.userDetails.customerRoleName === Roles.ViewOnly;
        this.isCustomerAgent = this.userDetails.customerRoleName === Roles.Agent;
        this.isProjectManager = this.userDetails.projectCustomerRoleName === Roles.Manager;
        if (this.isCustomerAdministator || this.isCustomerManager) {
            this.showPlanLibrary = true;
        }
    }
    filterReset(type: string) {
        this.commonService.removeItem((LocalStorageKeys as any)[`${type}`]);
    }
    private checkCustomer() {
        this.user = this.authService.getUserKeyData();
        this.userDetails = this.authService.getUserKeyData();
        this.checkGlobalRole();
        this.checkCustomerRole();
        this.isCustomer = false;
        this.isTechnician = false;
        this.isTicketSubmitter = false;
        this.canAccessCustomerUsers = false;
        this.user = this.authService.getUserKeyData();
        if (this.isAdministrator && !Utilities.isEmpty(this.userDetails.customerId)) {
            this.isCustomer = true;
            this.canAccessCustomerUsers = true;
        } else if (this.isUser && !Utilities.isEmpty(this.userDetails.customerId)) {
            if (this.isCustomerAdministator) {
                this.isCustomer = true;
                this.canAccessCustomerUsers = true;
            } else if (this.isCustomerViewOnly || this.isCustomerAgent || this.isCustomerManager) {
                this.isCustomer = true;
                this.canAccessCustomerUsers = false;
            } else if (this.isCustomerTechnician) {
                this.isCustomer = true;
                this.isTechnician = true;
            } else if (this.isCustomerTicketSubmitter) {
                this.isCustomer = true;
                this.isTicketSubmitter = true;
            }
        }
    }

    subscribe() {
        this.subscription = this.eventService.subscribe(EventEmitterType.CustomerChanged, (payload) => {
            this.userToken = this.authService.getDecodedJWTToken();
            this.checkCustomer();
            this.expandsideBarClass = false;
        });
        this.updateMenuWhileEnableDisable = this.eventService.subscribe(EventEmitterType.updateMenuWhileEnableDisable, (payload) => {
            if (payload.hasOwnProperty('isLiveSystemAccessForCustomer')) {
                this.isLiveSystemAccessForCustomer = _.cloneDeep(payload.isLiveSystemAccessForCustomer);
            } else if (payload.hasOwnProperty('ticket')) {
                this.isTicketAccessible = _.cloneDeep(payload.ticket);
            }
        });
    }
    saveVisitedMenu(menu?: any) {
        this.commonService.removeItem('menu')
        if (menu) {
            this.commonService.setLocalStorageString('menu', menu)
        }
    }
    getVisitedMenu() {
        if (!Utilities.isEmpty(this.commonService.getLocalStorageString('menu')) && !Utilities.isNull(this.commonService.getLocalStorageString('menu'))) {
            return this.commonService.getLocalStorageString('menu')
        }
    }

    ngOnDestroy(): void {
        if (this.subscription && this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
        if (this.headerSub && this.headerSub !== undefined) {
            this.headerSub.unsubscribe();
        }

        if (this.userUpdateDetailSub) {
            this.userUpdateDetailSub.unsubscribe();
        }

        if (this.updateMenuWhileEnableDisable) {
            this.updateMenuWhileEnableDisable.unsubscribe();
        }
    }

    expandMenu() {
        this.expandsideBarClass = !this.expandsideBarClass;
    }

    manageClick() {
        this.commonService.removeItem('userControl');
        this.router.navigateByUrl('/secure/admin/customer/list');
    }

    helpClick() {
        this.helpPopupClicked = true
        this.helpSubmenu = !this.helpSubmenu;
    }

    helpPopup() {
        this.helpPopupClicked = true;

    }

    isLivePlanningActive(): boolean {

        if (this.getVisitedMenu() === 'planning') {
            return true;
        }
        return false;
    }
}
